<template>
  <div class="list-group-item handle">
    <span class="badge badge-danger float-right" @click="deleteGroup(group.ID)"
      >Delete</span
    >

    <span class="badge badge-success float-right mr-1" @click="inlineEdit"
      >Edit</span
    >

    <span class="badge badge-default float-left mr-1 badge-sort">
      <i class="fa fa-sort-up" @click="moveUp" />
      <i class="fa fa-sort-down" @click="moveDown" />
    </span>

    <span v-if="!showInput">{{ group.post_title }}</span>
    <div class="input-group input-group-sm mt-1" v-else>
      <input
        v-model="groupTitle"
        type="text"
        class="form-control"
        placeholder="Recipient's username"
        aria-label="Recipient's username"
        aria-describedby="button-addon2"
      />
      <button
        class="btn btn-sm btn-success"
        type="button"
        id="button-addon2"
        @click="saveChanges"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import ChapterPageEditorModal from "./ChapterPageEditorModal";

export default {
  components: {},
  props: {
    group: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      showInput: false,
      newTitle: "",
    };
  },
  computed: {
    groupTitle: {
      get() {
        return this.group.post_title;
      },
      set(value) {
        this.newTitle = value;
      },
    },
    isActivated() {
      return this.group.legacy_journal === "1";
    },
  },
  methods: {
    activateItem() {
      this.group.legacy_journal = this.group.legacy_journal === `1` ? `0` : `1`;

      this.$store.dispatch("updatePageMeta", [
        this.group.ID,
        "legacy_journal",
        this.group.legacy_journal,
        ({ message }) => {
          this.$toast.open({
            message,
            position: "bottom-left",
          });
        },
      ]);
    },
    inlineEdit() {
      this.showInput = !this.showInput;
    },
    editGroup() {
      this.$modal.show(
        ChapterPageEditorModal,
        {
          item: this.group,
        },
        {
          name: "modal-group-page",
        }
      );
    },
    deleteGroup() {
      if (confirm("Are you sure?")) {
        this.deleted = this.group.ID;
        this.$store.dispatch("deleteWritingsGroupPage", [
          this.group.ID,
          ({ message, icon }) => {
            this.deleted = "";
            this.$toast.open({
              message: message,
              position: "bottom-left",
              type: icon,
            });
          },
        ]);
      }
    },
    saveChanges() {
      this.showInput = false;
      this.$store.dispatch("updatePostTitle", [
        this.group.ID,
        this.newTitle,
        ({ message, title }) => {
          this.group.post_title = title;
          this.$toast.open({
            message,
            position: "bottom-left",
          });
        },
      ]);
    },
    moveUp() {
      this.$emit("move-up");
    },
    moveDown() {
      this.$emit("move-down");
    },
  },
  mounted() {},
};
</script>
<style scoped>
.list-group {
  max-height: 515px;
  overflow-y: scroll;
}
.list-group-item {
  padding-top: 0.6rem;
  padding-bottom: 0.25rem;
}
.badge {
  cursor: pointer;
}
.fa.fa-sort {
  cursor: grabbing;
}
.fa.fa-check-square {
  cursor: pointer;
}
.activated {
  color: green;
}
.deactivated {
  color: #bdbdbd;
}
.badge-sort {
  display: flex;
  flex-direction: column;
  margin-top: -5px;
}
.badge-sort .fa {
  border: 1px solid #ccc;
  padding: 1px 3px;
  height: 13px;
}
.badge-sort .fa:hover {
  background: #bdbdbd;
}
.badge-sort .fa-sort-up {
  padding-top: 3px;
  padding-bottom: 0;
  height: 14px;
  border-bottom: none;
}
.badge-sort .fa-sort-down {
  padding-top: 0;
  padding-bottom: 3px;
  line-height: 0.6;
  border-top: none;
}
</style>