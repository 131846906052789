<template>
  <div id="my-writings-container">
    <Spinner v-if="loading" />
    <MyWritingsGroups v-if="!loading" @setGroup="setGroup" @setTrash="setTrash">
      <MyWritingsListTrash v-if="isTrash" />
      <MyWritingsList :group="group" :groupData="groupData" v-else />
    </MyWritingsGroups>
  </div>
</template>

<script>
import MyWritingsGroups from "./MyWritingsGroups";
import MyWritingsList from "./MyWritingsList";
import MyWritingsListTrash from "./MyWritingsListTrash";
import Spinner from "./Spinner";

export default {
  components: {
    MyWritingsGroups,
    MyWritingsList,
    Spinner,
    MyWritingsListTrash,
  },
  props: {},
  data() {
    return {
      loading: true,
      group: "all",
      isTrash: false,
      groupData: {},
    };
  },
  computed: {},
  methods: {
    setGroup(group) {
      this.group = group?.ID ? `${group.ID}` : `all`;
      this.groupData = group !== `all` && group !== `fjtl-trash` ? group : {};
      this.isTrash = false;
    },
    setTrash() {
      this.isTrash = true;
    },
  },
  mounted() {
    this.$store.dispatch("loadDefaults", [
      [
        "default_font_family",
        "default_font_size",
        "image_upload_limit",
        "image_upload_count",
      ],
      () => {
        this.$store.dispatch("loadAllLegacyJournal", () => {
          this.loading = false;
        });
      },
    ]);
  },
};
</script>
