<template>
  <div class="card-body">
    <CloseModalButton @close="closeModal" />
    <spinner size="small" v-if="loading" />
    <div class="card-body" v-else>
      <span class="card-title"
        >Are you sure you want to trash this writing?</span
      >
      <button class="btn btn-success btn-sm float-right" @click="triggerSubmit">
        Yes, Proceed!
      </button>
    </div>
  </div>
</template>

<script>
import CloseModalButton from "./CloseModalButton.vue";
import Spinner from "./Spinner.vue";

export default {
  components: { CloseModalButton, Spinner },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {},
  methods: {
    triggerSubmit() {
      this.loading = true;
      this.$store.dispatch("trashWriting", [
        this.item.ID,
        (data) => {
          this.item.post_status = data.post_status;
          this.$toast.open({
            message: "Writing deleted!",
            position: "bottom-left",
            type: "error",
          });
          this.$emit("close");
        },
      ]);
    },
    closeModal() {
      this.$emit("close");
    },
  },
  mounted() {},
};
</script>
