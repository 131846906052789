<template>
  <div class="container">
    <CloseModalButton @close="closeModal" />
    <PageNavButtons
      v-if="hasPageNav"
      :item="item"
      ref="viewNav"
      @nav-change="triggerChangeNav"
    />
    <div class="page-editor-modal row">
      <div class="col-md-12 pt-3 pb-3">
        <ContentEditorModalChapter
          v-if="active_view == `content`"
          :item="item"
          @reload-preview="reloadPreview"
          :title-input="true"
        />
        <DesignEditorModal
          v-if="active_view == `design`"
          :item="item"
          @reload-preview="reloadPreview"
          @set-design="setDesign"
        />
        <SubpagesEditorModal
          v-if="active_view == `subpages`"
          :item="item"
          @reload-preview="reloadPreview"
          ref="subPages"
        />

        <PreviewHtml :item="item" v-if="active_view == `preview`" />
      </div>
    </div>
  </div>
</template>

<script>
import CloseModalButton from "./CloseModalButton";
import PageNavButtons from "./PageNavButtons";
import ContentEditorModalChapter from "./ContentEditorModalChapter";
import DesignEditorModal from "./DesignEditorModal";
import SubpagesEditorModal from "./SubpagesEditorModal";
import PreviewHtml from "./PreviewHtml";

export default {
  components: {
    CloseModalButton,
    PageNavButtons,
    ContentEditorModalChapter,
    DesignEditorModal,
    SubpagesEditorModal,
    PreviewHtml,
  },
  data() {
    return {
      active_view: "content",
    };
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    closeTo: {
      type: String,
      default: "pages",
    },
  },
  computed: {
    items: {
      get() {
        return this.$store.getters.getLegacyJournal;
      },
      set(value) {
        this.$store.commit("SET_LEGACY_JOURNAL", value);
      },
    },
    getTitle() {
      return this.items[this.index]?.post_title ?? "";
    },
    getBody() {
      return this.items[this.index]?.post_content ?? "";
    },
    getWritingData: {
      get() {
        return this.$store.getters.getLegacyJournal.find((journal) => {
          return parseInt(journal.ID) === parseInt(this.item.writing_id);
        });
      },
    },
    hasPageNav() {
      return this.$root?.sidebar_tabs === `1`;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    reloadPreview() {
      this.$refs.preview.reload();
    },
    triggerChangeNav() {
      this.active_view = this.$refs.viewNav.isActive;
      this.$refs.subPages?.emptySelectedPage();
    },
    setDesign() {
      this.active_view = "preview";
      this.$refs.viewNav.active = "preview";
    },
  },
  mounted() {
    // console.log("Chapter Editor");
  },
};
</script>