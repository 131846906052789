<template>
  <div class="card-body">
    <CloseModalButton @close="closeModal" />
    <div class="input-group mb-2">
      <input
        type="text"
        class="form-control"
        :placeholder="placeholder"
        v-model="groupName"
        @keyup="triggerKeyUp"
        :class="{ disabled: adding }"
        :disabled="adding"
      />
      <div class="input-group-append">
        <button
          class="btn btn-success"
          @click="addNewGroup"
          :disabled="!buttonActive"
        >
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>

    <draggable
      class="list-group"
      v-model="items"
      @change="updatePosition"
      handle=".handle"
    >
      <my-writings-manage-group-item
        :key="group.ID"
        v-for="(group, index) in filterDeleted"
        :group="group"
        @move-up="moveUp(index)"
        @move-down="moveDown(index)"
      />
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import CloseModalButton from "./CloseModalButton";
import MyWritingsManageGroupItem from "./MyWritingsManageGroupItem.vue";

export default {
  components: { draggable, CloseModalButton, MyWritingsManageGroupItem },
  props: {},
  data() {
    return {
      groupName: "",
      adding: false,
      deleted: "",
      buttonActive: false,
      placeholder: `Enter Chapter/Divider Name here (ex. "Chapter One" or "Early Years")`,
    };
  },
  computed: {
    ...mapGetters(["getWritingGroupPages"]),
    items: {
      get() {
        return this.getWritingGroupPages;
      },
      set(value) {
        this.$store.commit("SET_WRITINGS_GROUP_PAGES", { items: value });
      },
    },
    filterDeleted() {
      return this.getWritingGroupPages.filter((p) => p.ID !== this.deleted);
    },
  },
  methods: {
    triggerKeyUp(evt) {
      if (this.groupName.length > 0) {
        this.buttonActive = true;
        if (evt.code === `Enter`) {
          this.addNewGroup();
        }
      } else {
        this.buttonActive = false;
      }
    },
    addNewGroup() {
      if (this.groupName === ``) return;
      this.adding = true;
      this.buttonActive = false;
      this.$store.dispatch("addWritingsGroupPage", [
        this.groupName,
        this.getWritingGroupPages.length,
        ({ message, icon }) => {
          this.adding = false;
          this.groupName = "";
          this.$toast.open({
            message: message,
            position: "bottom-left",
            type: icon,
          });
        },
      ]);
    },

    updatePosition() {
      this.$store.dispatch(
        "updateWritingsGroupPagePosition",
        ({ success, data: { message } }) => {
          if (success) {
            this.$toast.open({
              message,
              position: "bottom-left",
            });
          }
        }
      );
    },
    closeModal() {
      this.$emit("close");
    },

    moveUp(index) {
      const new_index = index - 1 < 0 ? 0 : index - 1;
      this.moveItem(index, new_index);
    },
    moveDown(index) {
      const new_index = index + 1;
      this.moveItem(index, new_index);
    },
    moveItem(from, to) {
      const items = this.filterDeleted;
      var f = items.splice(from, 1)[0];
      items.splice(to, 0, f);
      this.items = items;
      this.updatePosition();
    },
  },
  mounted() {
    this.$store.dispatch("setGlobalSettings", ["designs", `1`]);
  },
};
</script>
<style scoped>
.list-group {
  max-height: 515px;
  overflow-y: scroll;
}
.badge {
  cursor: pointer;
}
.fa.fa-sort {
  cursor: grabbing;
}
.fa.fa-check-square {
  cursor: pointer;
}
.activated {
  color: green;
}
.deactivated {
  color: #bdbdbd;
}
</style>