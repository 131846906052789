<template>
  <div>
    <spinner v-if="loading" />
    <table v-else class="table table-striped table-bordered">
      <thead class="thead-dark" v-if="filteredTrash.length > 0">
        <tr>
          <th scope="col">Prompt</th>
          <th width="5%" scope="col"></th>
          <th width="5%" scope="col"></th>
        </tr>
        <!--<MyWritingsListChapter :groupData="groupData" v-if="isGroup" />-->
      </thead>
      <tbody v-if="filteredTrash.length > 0">
        <tr v-for="item in filteredTrash" :key="item.ID">
          <td>{{ item.post_title }}</td>
          <td>
            <button
              class="btn btn-warning btn-sm"
              @click="triggerRestore(item)"
            >
              Restore Writing
            </button>
          </td>
          <td>
            <button class="btn btn-danger btn-sm" @click="triggerErase(item)">
              Delete Permanently
            </button>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td class="text-center">Trash Empty</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import DeleteWritingsModal from "./DeleteWritingsModal.vue";
import RestoreWritingsModal from "./RestoreWritingsModal.vue";
import { mapGetters } from "vuex";
import Spinner from "./Spinner.vue";
export default {
  components: { Spinner },
  props: {},
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["getTrashedPages"]),
    filteredTrash() {
      return this.getTrashedPages.filter((i) => i.post_status === "trash");
    },
  },
  methods: {
    triggerRestore(item) {
      this.$modal.show(
        RestoreWritingsModal,
        {
          item: item,
        },
        {
          name: "modal-prompt",
          width: "55%",
          height: "auto",
        }
      );
    },
    triggerErase(item) {
      this.$modal.show(
        DeleteWritingsModal,
        {
          item: item,
        },
        {
          name: "modal-prompt",
          width: "55%",
          height: "auto",
        }
      );
    },
  },
  mounted() {
    this.$store.dispatch("loadTrashedLegacyJournal", () => {
      this.loading = false;
    });
  },
};
</script>
