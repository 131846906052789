<template>
  <tr id="writing-item-15650">
    <th scope="row" class="text-center">
      <span class="module-button">
        <i class="fa fa-trash" @click="triggerDelete" />
      </span>
    </th>
    <td>
      <span class="writing-title" @click="showPopup">{{ getPostTitle }}</span>
    </td>
    <td class="text-center" v-if="hasGroups">
      <MyWritingsGroupsDropdown :item="item" />
    </td>
    <td class="text-center">
      <MyWritingsListItemCheckbox :item="item" />
    </td>
    <td class="text-center">
      <MyWritingsListItemSendViaEmail :item="item" />
    </td>
  </tr>
</template>

<script>
import PageEditorModal from "./PageEditorModal.vue";
import TrashWritingsModal from "./TrashWritingsModal.vue";
import MyWritingsGroupsDropdown from "./MyWritingsGroupsDropdown.vue";
import MyWritingsListItemCheckbox from "./MyWritingsListItemCheckbox.vue";
import MyWritingsListItemSendViaEmail from "./MyWritingsListItemSendViaEmail.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    MyWritingsGroupsDropdown,
    MyWritingsListItemCheckbox,
    MyWritingsListItemSendViaEmail,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getWritingGroupPages"]),
    hasGroups() {
      return this.getWritingGroupPages.length > 0;
    },
    getPostTitle() {
      return this.item.post_title !== ``
        ? this.item.post_title
        : "- - no title - -";
    },
    getModule() {
      return this.item.module_num ? this.item.module_num : 1;
    },
  },
  methods: {
    showPopup() {
      this.$modal.show(
        PageEditorModal,
        {
          item: this.item,
        },
        {
          name: "modal-prompt",
          resizable: true,
          minWidth: 880,
          minHeight: 605,
        }
      );
    },
    triggerDelete() {
      this.$modal.show(
        TrashWritingsModal,
        {
          item: this.item,
        },
        {
          name: "modal-prompt",
          width: "55%",
          height: "auto",
        }
      );
    },
    filterByModule() {
      this.$emit("filterByModule", this.getModule);
    },
  },
  mounted() {
    // console.log(this.item);
    this.$store.dispatch("setGlobalSettings", ["designs", `1`]);
  },
};
</script>
<style scoped>
.writing-title {
  cursor: pointer;
  color: blue;
}
.module-button {
  cursor: pointer;
}
.fa-trash:hover {
  color: red;
}
</style>