<template>
  <table class="table table-striped table-bordered">
    <thead class="thead-dark" v-if="draggableItems.length > 0">
      <tr>
        <th width="1%" scope="col"></th>
        <th scope="col">Prompt</th>
        <th width="155px" scope="col" class="text-center" v-if="hasGroups">
          Group
        </th>
        <th width="155px" scope="col" class="text-center">
          Add To Legacy Journal
        </th>
        <th width="100px" scope="col" class="text-center">Send via Email</th>
      </tr>
    </thead>

    <draggable
      v-model="draggableItems"
      tag="tbody"
      @change="updatePosition"
      v-if="draggableItems.length > 0"
    >
      <MyWritingsListItem
        v-for="item in draggableItems"
        :key="item.ID"
        :item="item"
        @filterByModule="filterByModule"
        class="handle"
      />
    </draggable>
    <tbody v-else>
      <tr>
        <td class="text-center">Group Empty</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
// import MyWritingsListChapter from "./MyWritingsListChapter.vue";
import MyWritingsListItem from "./MyWritingsListItem";

export default {
  components: {
    MyWritingsListItem,
    // MyWritingsListChapter,
    draggable,
  },
  props: {
    group: {
      type: String,
      default: "all",
    },
    groupData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      module_num: 1,
      filter_by_module: false,
    };
  },
  computed: {
    ...mapGetters(["getPages", "getWritingGroupPages"]),
    hasGroups() {
      return this.getWritingGroupPages.length > 0;
    },
    filteredBy() {
      return this.isGroup ? `menu_order` : `position`;
    },
    filteredPages() {
      if (this.group === `all`) {
        return this.getPages
          .filter(() => true)
          .sort((a, b) => a[this.filteredBy] - b[this.filteredBy]);
      } else {
        return this.getPages
          .filter((item) => {
            return parseInt(item.writing_group) === parseInt(this.group);
          })
          .sort((a, b) => a[this.filteredBy] - b[this.filteredBy]);
      }
    },
    filteredByModule() {
      if (this.filter_by_module) {
        return this.filteredPages
          .filter((item) => {
            return item.module_num === this.module_num;
          })
          .sort((a, b) => a[this.filteredBy] - b[this.filteredBy]);
      } else {
        return this.filteredPages
          .filter(() => true)
          .sort((a, b) => a[this.filteredBy] - b[this.filteredBy]);
      }
    },
    isGroup() {
      return typeof this.groupData?.ID !== `undefined`;
    },

    draggableItems: {
      get() {
        return this.filteredByModule.filter(
          (item) => item.post_status !== "trash"
        );
      },
      set(value) {
        value.map((item, index) => {
          item.menu_order = index;
        });
      },
    },
  },
  methods: {
    filterByModule(module) {
      this.module_num = module;
      this.filter_by_module = !this.filter_by_module;
    },
    updatePosition() {
      this.$store.dispatch("updateLegacyJournalPositionByGroup", [
        this.draggableItems,
        ({ success, data: { message } }) => {
          if (success) {
            this.$toast.open({
              message,
              position: "bottom-left",
            });
          }
        },
      ]);
    },
  },
  mounted() {},
};
</script>
