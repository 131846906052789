<template>
  <div class="card-body">
    <CloseModalButton @close="closeModal" />
    <spinner size="small" v-if="sending" />
    <div class="input-group" v-else>
      <input
        type="text"
        class="form-control"
        placeholder="Recipient's Email
      Address"
        aria-label="Recipient's Email Address"
        v-model="email"
        :class="validEmail ? 'is-valid' : 'is-invalid'"
      />
      <div class="input-group-append">
        <span
          class="btn"
          :class="validEmail ? 'btn-success' : 'btn-danger disabled'"
          @click="sendNow"
          >Send Now</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "./Spinner.vue";
import CloseModalButton from "./CloseModalButton.vue";

export default {
  components: { Spinner, CloseModalButton },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      email: "",
      sending: false,
    };
  },
  computed: {
    validEmail() {
      var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      return regex.test(this.email);
    },
  },
  methods: {
    sendNow() {
      if (this.validEmail) {
        this.sending = true;
        this.$store.dispatch("sendViaEmail", [
          this.item.ID,
          this.email,
          ({ message, icon }) => {
            this.$emit("close");
            this.sending = false;
            this.$toast.open({
              message,
              position: "bottom-left",
              type: icon,
            });
          },
        ]);
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
  mounted() {},
};
</script>
