<template>
  <div class="card">
    <CloseModalButton @close="closeModal" />
    <div class="card-body" v-if="loading">
      <spinner size="small" />
    </div>
    <div class="card-body" v-else>
      <h4 class="card-title">
        Are you sure you want to delete this permanently?
      </h4>
      <hr />
      <p>
        <strong>CAUTION:</strong> This writing will be removed from the
        database. <br />You will never be able to retrieve it back.
      </p>
    </div>
    <div class="card-footer" v-if="!loading">
      <button class="btn btn-danger" @click="triggerSubmit">
        YES, Delete Permanently!
      </button>
    </div>
  </div>
</template>

<script>
import CloseModalButton from "./CloseModalButton.vue";
import Spinner from "./Spinner.vue";

export default {
  components: { CloseModalButton, Spinner },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {},
  methods: {
    triggerSubmit() {
      this.loading = true;
      this.$store.dispatch("eraseWriting", [
        this.item.ID,
        () => {
          this.item.post_status = "erased";
          this.$toast.open({
            message: "Writing permanently deleted!",
            position: "bottom-left",
            type: "error",
          });
          this.$emit("close");
        },
      ]);
    },
    closeModal() {
      this.$emit("close");
    },
  },
  mounted() {},
};
</script>
