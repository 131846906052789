<template>
  <div>
    <div class="group-container">
      <div class="description">
        Click <strong @click="showManager">HERE</strong> to create "folders" to
        organize your writings by themes, chapters or time periods
      </div>
      <ul class="nav nav-tabs nav-groups">
        <li class="nav-item">
          <a
            class="nav-link"
            :class="{ active: active === `all` }"
            @click="setAll()"
            >All Writings</a
          >
        </li>
        <li
          class="nav-item"
          v-for="group in getWritingGroupPages"
          :key="group.id"
        >
          <a
            class="nav-link"
            :class="{ active: active === group.ID }"
            @click="changeActive(group)"
            >{{ group.post_title }}</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            :class="{ active: active === `fjtl-trash` }"
            @click="setTrash"
            >Trash</a
          >
        </li>
      </ul>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MyWritingsManageGroups from "./MyWritingsManageGroups";

export default {
  components: {},
  props: {},
  data() {
    return {
      active: "all",
    };
  },
  computed: {
    ...mapGetters(["getWritingGroupPages"]),
  },
  methods: {
    changeActive(group) {
      this.active = group.ID ?? `all`;
      this.$emit("setGroup", group);
    },
    setTrash() {
      this.active = "fjtl-trash";
      this.$emit("setTrash");
    },
    setAll() {
      this.active = "all";
      this.$emit("setGroup", this.active);
    },
    showManager() {
      this.$modal.show(
        MyWritingsManageGroups,
        {
          item: this.item,
        },
        {
          name: "modal-manage-groups",
          width: "55%",
        }
      );
    },
  },
  created() {},
  mounted() {
    this.$store.dispatch("loadWritingsGroupPages", () => {});
  },
};
</script>
<style scoped>
.nav-groups {
  width: calc(100% - 210px);
}
.nav-link {
  cursor: pointer;
  color: blue !important;
}
.group-container {
  position: relative;
}
.description {
  position: absolute;
  width: 240px;
  right: 0;
  bottom: 5px;
  font-size: 14px;
}
.description > strong {
  color: blue;
}
.description > strong:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>