<template>
  <button class="btn btn-success btn-sm" @click="showPopup">
    Send via Email
  </button>
</template>

<script>
import SendViaEmailModal from "./SendViaEmailModal.vue";

export default {
  components: {},
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    showPopup() {
      this.$modal.show(
        SendViaEmailModal,
        {
          item: this.item,
        },
        {
          name: "modal-prompt",
          width: "55%",
          height: "auto",
        }
      );
    },
  },
  mounted() {},
};
</script>
