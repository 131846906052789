<template>
  <div class="dropdown">
    <button
      class="btn btn-secondary btn-sm dropdown-toggle"
      type="button"
      data-toggle="dropdown"
    >
      {{ getCurrentGroup }}
    </button>
    <div class="dropdown-menu">
      <a
        v-for="group in filteredGroups"
        :key="group.ID"
        class="dropdown-item"
        href="javascript:void(0);"
        @click="changeItemGroup(group)"
        >{{ group.post_title }}</a
      >
      <a
        class="dropdown-item"
        href="javascript:void(0);"
        @click="removeItemGroup()"
        v-if="isCurrentGroup"
        >All Writings</a
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getWritingGroupPages"]),
    currentGroup() {
      const [currentGroup] = this.getWritingGroupPages.filter(
        (g) => g.ID === parseInt(this.item.writing_group)
      );
      return currentGroup;
    },
    getCurrentGroup() {
      const [currentGroup] = this.getWritingGroupPages.filter(
        (g) => g.ID === parseInt(this.item.writing_group)
      );
      return currentGroup ? currentGroup.post_title : `Add to Group`;
    },
    isCurrentGroup() {
      const [currentGroup] = this.getWritingGroupPages.filter(
        (g) => g.ID === parseInt(this.item.writing_group)
      );
      return currentGroup ? true : false;
    },
    filteredGroups() {
      if (this.currentGroup) {
        return this.getWritingGroupPages.filter(
          (g) => g.ID !== this.currentGroup.ID
        );
      } else {
        return this.getWritingGroupPages;
      }
    },
  },
  methods: {
    removeItemGroup() {
      this.item.writing_group = 0;
      this.$store.dispatch("updatePageMeta", [
        this.item.ID,
        "writing_group",
        `0`,
        ({ message }) => {
          this.$toast.open({
            message,
            position: "bottom-left",
            type: "success",
          });
        },
      ]);
    },
    changeItemGroup(group) {
      this.item.writing_group = group.ID;
      this.$store.dispatch("changeItemWritingsGroupPage", [
        this.item.ID,
        group.ID,
        group.post_title,
        ({ message, icon }) => {
          this.$toast.open({
            message: message,
            position: "bottom-left",
            type: icon,
          });
        },
      ]);
    },
  },
  mounted() {
    // console.log(this.item);
  },
};
</script>
