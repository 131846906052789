<template>
  <div class="btn-legacy-journal">
    <label class="active">
      <input
        type="checkbox"
        class="add-to-legacy-journal"
        v-model="inJournal"
        @click="triggerLegacyJournal($event.target.checked)"
      />
    </label>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    inJournal: {
      get() {
        return this.item.legacy_journal === `1`;
      },
      set(val) {
        this.item.legacy_journal = val ? `1` : `0`;
      },
    },
  },
  methods: {
    triggerLegacyJournal(val) {
      this.$store.dispatch("updatePageMeta", [
        this.item.ID,
        "legacy_journal",
        val ? `1` : `0`,
        ({ message }) => {
          this.$toast.open({
            message,
            position: "bottom-left",
            type: "success",
          });
        },
      ]);
    },
  },
  mounted() {
    // console.log(this.item.legacy_journal);
  },
};
</script>
